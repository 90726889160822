import axios from 'axios'

const getDefaultState = () => {
  return {}
}

const state = getDefaultState()

const mutations = {}

const actions = {
  fetchTableData ({commit, rootState, dispatch}, database ) {

    // http://localhost:21553/rdm/BICYCLE_DB/?list=tables&columns=on
    return axios.get('http://'+ rootState.auth.api_address+':'+rootState.auth.port_number+'/rdm/' + database + '/?list=tables&columns=on');
    
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

